<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Ticket Queues')" title-actions>
      <template #titleActions>
        <v-btn to="TicketQueues/create" color="secondary" rounded dark>
          <v-icon>mdi-plus</v-icon>
          <span class="d-none d-sm-inline">Ticket Queue erstellen</span>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="ticketQueues"
        :page="pagination ? pagination.page : 1"
        :items-per-page="pagination ? pagination.perPage : 20"
        :loading="pending.getTicketQueues"
        hide-default-footer
      >
        <template #[`item.user`]="{ item }">{{ item.user.firstname }} {{ item.user.lastname }}</template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="ticketQueues" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard.vue'
import AppPagination from '@/components/UI/AppPagination.vue'
import OverviewActions from '@/components/UI/OverviewActions.vue'
import OverviewWrapper from '@/layouts/OverviewWrapper.vue'

export default defineComponent({
  name: 'ViewTicketQueues',
  components: {
    AppCard,
    AppPagination,
    OverviewActions,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $router }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Moderator',
        value: 'user',
      },
      {
        sortable: false,
        text: 'Open Tickets',
        value: 'ticketcount',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.ticketQueues.pending)
    const ticketQueues = computed(_ => $store.state.ticketQueues.ticketQueues)
    const pagination = computed(_ => $store.state.ticketQueues.pagination)
    const getTicketQueues = page => $store.dispatch('ticketQueues/getTicketQueues', page)
    const deleteTicketQueue = queueId => $store.dispatch('ticketQueues/deleteTicketQueue', queueId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getTicketQueues(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getTicketQueues(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass die Ticket Queue gelöscht werden soll?')) {
        if (pending.value.deleteTicketQueue) {
          return
        }
        deleteTicketQueue(id)
          .then(_ => {
            addToast({
              msg: 'Ticket Queue erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getTicketQueues(page.value)
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      ticketQueues,
      headers,
      pagination,
      remove,
    }
  },
}) //
</script>
